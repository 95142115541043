require("expose-loader?$!jquery")
import Clipboard from 'clipboard'
import Vue from 'vue/dist/vue.esm'

export const pageSetup = () => {

  window.PHLDSA.mainVue = new Vue({
    el: '#app',
    mounted() {
      window.setTimeout( () => $(this.el).find('input[autofocus], textarea[autofocus]').focus(), 10)
    }
  })

  // Set up Foundation after vue is instantiated
  $(document).foundation()
  new Clipboard('.clipboard-button');
}