import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")

const colors = ['#C6F3DC','#89E6B8', '#34D993', '#00CC6D', '#00AD5C', '#008E4C', '#006F3B']
Vue.component('mailing-clicks', {
  props: [
    'clickData'
  ],
  mounted() {
    this.links = JSON.parse(this.clickData)
    this.colorLinks()
  },
  data() {
    return {
      links: []
    }
  },
  methods: {
    colorLinks() {
      var $el = $(this.$el)

      this.links.forEach(item => {
        let link = $el.find(`a[href='${item[0]}']:not([title])`)
        // let clicksIndex = Math.min(item[2] / 2.2 - 1, 6)
        // let color = colors[Math.round(clicksIndex)]
        let clicksIndex = Math.min(item[2] / 2 - 1, 10)
        // let clicksIndex = Math.min(item[2] / 2.2 - 1, 6)
        let alpha = clicksIndex / 10
        link.css('background', `rgba(0,173,92, ${alpha})` )
        link.attr('title',`${item[2]} clicks`)
        link.append(`<span class="clks">${item[2]}</span>`)
      })
    }
  }
})