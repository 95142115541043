// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("expose-loader?$!jquery")

require("@rails/ujs").start()
require("turbolinks").start()
import Foundation from 'foundation-sites';
import { pageSetup } from 'modules/page_setup';

require('modules/mailing_clicks')

Foundation.addToJquery(jQuery)
window.PHLDSA = {}
$(document).on('turbolinks:load', pageSetup)
// $(document).ready(pageSetup)
